(function (window) {
  var contents = {
    header: '',
    message: 'Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. ' +
      'Da wir Ihre Privatsphäre schätzen, bitten wir Sie hiermit um Erlaubnis, auch Cookies von unseren Partnern zuzulassen, um Ihnen interaktive Inhalte zur Verfügung stellen zu können. ' +
      'Sie können Ihre Einwilligung später jederzeit widerrufen, ' + (
        // Change the text accordingly if the pointer device supports hovering
        (window.matchMedia && window.matchMedia('(hover: hover)').matches) ?
          'indem Sie mit der Maus an den oberen Bildschirmrand fahren und die sich öffnende Schaltfläche betätigen.' :
          'indem Sie die entsprechende Schaltfläche am linken Bildschirmrand betätigen.'
      ) + ' Weitere Informationen zu diesem Thema erhalten Sie in unserer',
    dismiss: 'Verstanden',
    allow: 'Cookies erlauben',
    deny: 'Ablehnen',
    link: 'Datenschutzerklärung',
    policy: {
      allow: 'Einwilligung für Cookies widerrufen',
      deny: 'Entscheidung für Cookies widerrufen',
      dismiss: 'Entscheidung für Cookies widerrufen',
    }
  }

  window.addEventListener('load', function (event) {
    if (document.body.classList.contains('neos-backend')) {
      return loadSensitiveScripts();
    }

    var gdprLink = '#';

    var footerMenu = document.querySelector('footer > div.footer-top > div.footer-menu');
    var privacyPageItem = footerMenu.querySelector('a[title="Datenschutz"]');

    if (privacyPageItem && privacyPageItem.hasAttribute('href')) {
      gdprLink = privacyPageItem.getAttribute('href');
    }

    window.cookieconsent.initialise({
      enabled: true,
      type: 'opt-in',
      position: 'top',
      theme: 'block',
      static: true,
      autoOpen: true,
      palette: {
        popup: {
          background: '#000',
          text: '#fff',
          link: '#7595c9',
        },
        button: {
          background: '#a1bf65',
          border: '#000',
          text: '#000',
        },
      },
      content: Object.assign({}, contents, {
        href: gdprLink,
        close: '&#x274c;',
        policy: 'Einwilligung für Cookies widerrufen', // Dynamically set, see below
        target: '_blank',
      }),
      onInitialise: function (status) {
        // Wait for the revoke button to appear, then change it's contents
        var _updateContent = setInterval(function () {
          var policyBtn = document.body.querySelector('div.cc-revoke');
          if (policyBtn !== null) {
            policyBtn.innerHTML = contents.policy[status];
            clearInterval(_updateContent);
          }
        }, 100);

        if (status == cookieconsent.status.allow) {
          loadSensitiveScripts();
        }
      },
      onStatusChange: function (status, chosenBefore) {
        document.body.querySelector('div.cc-revoke').innerHTML = contents.policy[status];

        if (status == cookieconsent.status.allow) {
          loadSensitiveScripts();
        }
      },
      onRevokeChoice: function () {
        deleteAllCookies();
        alert('Sie haben Ihre Entscheidung zu Cookies erfolgreich widerufen. Die Seite lädt jetzt neu damit die Änderungen wirksam werden.');
        window.location.reload();
      }
    });
  });

  function loadSensitiveScripts() {
    var elements = document.querySelectorAll('script.invoke-on-consent');

    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];
      element.setAttribute('type', 'text/javascript');
      element.setAttribute('src', element.getAttribute('data-src'));
      element.removeAttribute('data-src');
      element.classList.remove('invoke-on-consent');
    }
  }

  function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

      eraseCookieFromAllPaths(name);
    }
  }

  function eraseCookieFromAllPaths(name) {
    var pathBits = location.pathname.split('/');
    var pathCurrent = ' path=';

    document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;';

    for (var i = 0; i < pathBits.length; i++) {
      pathCurrent += ((pathCurrent.substr(-1) != '/') ? '/' : '') + pathBits[i];
      document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;' + pathCurrent + ';';
    }
  }
})(window);