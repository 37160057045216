var mainMenu = new function() {
  this.init = function () {
    if ($('body').hasClass('neos-backend')) {
      return false;
    }

    $('body > div > header .nav-button').on('click', function(e) {
      if ($(this).hasClass('menu-open')) {
        $('body').removeClass('modal-open');

        $(this).add($('body > div > header #main-menu'))
          .add('body > div > header #main-menu .category-menu-open')
          .add($('body > div > header #main-menu .supplement-menu'))
          .add($('body > div > header #menu-search'))
          .add($('body > div > header #menu-contact-persons'))
          .add($('body > div > header .menu-triggers .sub-triggers'))
          .add($('body > div > header .menu-triggers .sub-triggers .search-button'))
          .add($('body > div > header .menu-triggers .sub-triggers .contact-persons-button'))
          .add($('body > div > header #menu-contact-persons .contact-persons-inner .sub-filter-open'))
          .add($('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-wrapper'))
          .removeClass('menu-open submenu-open category-menu-open supplement-menu-open search-open contact-persons-open sub-filter-open open active');

        if ($(window).width() < smBreakpoint) {
          $('body > div > header #main-menu > .supplement-menu').hide();
          $('body > div > header #main-menu .submenu').hide().removeClass('submenu-open');
        }
      } else {
        $('body').addClass('modal-open');
        $(this).add($('body > div > header #main-menu')).addClass('menu-open');
        setTimeout(function(){ $('body > div > header #main-menu > .supplement-menu').show(); }, 600);
      }
      e.preventDefault();
    });

    $('body > div > header .sub-triggers .to-main-menu').on('click', function(e) {
      $('body > div > header .nav-button').trigger('click').trigger('click');
      e.preventDefault();
    });

    this.initSubmenu();
    this.initSupplementMenu();
    this.initCategoryMenu();
    this.initMenuSearch();
    this.initContactPersons();
    this.initLetterSizing();
    this.initLetterContrast();
  },

  this.initSubmenu = function() {
    var obj = this;

    if ($(window).width() >= smBreakpoint) {
      $('body > div > header #main-menu .submenu').first().show();
      $('body > div > header #main-menu .open-submenu').first()
        .add($('body > div > header #main-menu'))
        .addClass('submenu-open');
    }

    $('body > div > header #main-menu .open-submenu').on('click', function(e) {
      $('.submenu').hide()
        .add('.open-submenu')
        .removeClass('submenu-open');
      $('body > div > header #main-menu .submenu .category-menu .active').removeClass('active');
      var categoryMenu = $(this).siblings('.submenu').show().find('.category-menu').first().addClass('category-menu-open');
      var firstFolderLink = categoryMenu.find('a[href="#"]').first();
      firstFolderLink.add(firstFolderLink.siblings('.category-submenu')).addClass('active');
      var image = categoryMenu.find('.image').first();
      if (image) {
        obj.setCategoryImage(image);
      }
      var that = this;
      setTimeout(function(){ $(that).add($(that).siblings('.submenu'))
                              .add($('body > div > header #main-menu'))
                              .addClass('submenu-open'); }, 50);
      e.preventDefault();
    });

    $('body > div > header #main-menu .close-submenu').on('click', function(e) {
      $(this).parent().parent('.submenu')
        .add($('body > div > header #main-menu'))
          .removeClass('submenu-open');

      if ($(window).width() <= smBreakpoint) {
        setTimeout(function(){ $('body > div > header #main-menu .submenu').hide(); }, 600);
      }
      e.preventDefault();
    });
  },

  this.initSupplementMenu = function() {
    $('body > div > header #main-menu .supplement-menu-trigger').on('click', function(e) {
      if ($('body > div > header #menu-search').hasClass('search-open') || $('body > div > header #menu-contact-persons').hasClass('contact-persons-open')) {
        $('body > div > header #menu-search')
          .add($('body > div > header #menu-contact-persons'))
          .add($('body > div > header #menu-contact-persons .contact-persons-inner .sub-filter-open'))
          .add($('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-wrapper'))
          .add($('body > div > header #main-menu .supplement-menu'))
          .removeClass('search-open contact-persons-open sub-filter-open open');
        setTimeout(function() { $('body > div > header > #main-menu').toggleClass('supplement-menu-open') }, 600);
      } else {
        $('body > div > header > #main-menu').toggleClass('supplement-menu-open');
      }
      e.preventDefault();
    });
  }

  this.initCategoryMenu = function() {
    var obj = this;

    $('body > div > header #main-menu .submenu .category-menu > li > a[href="#"]').on('click', function(e) {
      $('body > div > header #main-menu .category-menu-open a')
        .add($('body > div > header #main-menu .category-menu-open .category-submenu'))
        .removeClass('active');
      var image = $(this).add($(this).siblings('.category-submenu')).addClass('active').find('.image');
      if (image) {
        obj.setCategoryImage(image);
      }
      e.preventDefault();
    });

    $('body > div > header #main-menu .submenu .category-menu .close-category-menu').on('click', function(e) {
      $(this).parent('.category-menu-open').removeClass('category-menu-open');
      e.preventDefault();
    });
  }

  this.setCategoryImage = function(obj) {
    switch(true) {
      case($(window).width() < lgBreakpoint):
        setBackgroundImage(obj, $(obj).data('src-md'));
        break;
      default:
        setBackgroundImage(obj, $(obj).data('src-lg'));
        break;
    }

    function setBackgroundImage(obj, src) {
      obj.css({'background-image': 'url('+src+')', 'background-position-y': $(obj).data('position')+'%'});
    }
  }

  this.initMenuSearch = function() {
    $('body > div > header #main-menu .supplement-menu .supplement-menu-item.search').add($('body > div > header .menu-triggers .sub-triggers .search-button')).on('click', function(e) {
      if ($('body > div > header #menu-contact-persons').hasClass('contact-persons-open')) {
        $('body > div > header #menu-contact-persons .close-contact-persons').trigger('click');
      }

      $('body').addClass('modal-open');
      $('body > div > header #menu-search')
        .add($('body > div > header #main-menu .supplement-menu'))
        .addClass('search-open');
      $('body > div > header .menu-triggers .sub-triggers')
        .add($('body > div > header .menu-triggers .sub-triggers .search-button'))
        .addClass('active');
      $('body > div > header .nav-button').addClass('menu-open');

      if ($(window).width() >= smBreakpoint) {
        $('body > div > header #main-menu').removeClass('menu-open');
      }

      e.preventDefault();
    });

    $('body > div > header #menu-search .close-search').on('click', function(e) {
      if ($(window).width() >= smBreakpoint) {
        $('body').removeClass('modal-open');
      }

      $('body > div > header #menu-search')
        .add($('body > div > header #main-menu .supplement-menu'))
        .removeClass('search-open');
      $('body > div > header .menu-triggers .sub-triggers')
        .add($('body > div > header .menu-triggers .sub-triggers .search-button'))
        .removeClass('active');

      e.preventDefault();
    });
  }

  this.initContactPersons = function () {
    var rawContactPersons;
    var keys = new Array;
    var departments = new Object;

    $('body > div > header #main-menu .supplement-menu .supplement-menu-item.contact-persons')
    .add($('body > div > header .menu-triggers .sub-triggers .contact-persons-button'))
    .on('click', function(e) {
      if ($('body > div > header #menu-search').hasClass('search-open')) {
        $('body > div > header #menu-search .close-search').trigger('click');
      }

      $('body').addClass('modal-open');
      $('body > div > header #menu-contact-persons').addClass('contact-persons-open');
      $('body > div > header .menu-triggers .sub-triggers')
        .add($('body > div > header .menu-triggers .sub-triggers .contact-persons-button'))
        .addClass('active');
      $('body > div > header #main-menu .supplement-menu').addClass('search-open');
      $('body > div > header .nav-button').addClass('menu-open');

      if (!$('body > div > header #menu-contact-persons').hasClass('initialized')) {
        getContactPersons();
      }

      if ($(window).width() >= smBreakpoint) {
        $('body > div > header #main-menu').removeClass('menu-open');
      }

      e.preventDefault();
    });

    $('body > div > header #menu-contact-persons .close-contact-persons').on('click', function(e) {
      if ($(window).width() >= smBreakpoint) {
        $('body').removeClass('modal-open');
      }

      $('body > div > header #menu-contact-persons').removeClass('contact-persons-open');
      $('body > div > header #main-menu .supplement-menu').removeClass('search-open');
      $('body > div > header .menu-triggers .sub-triggers')
        .add($('body > div > header .menu-triggers .sub-triggers .contact-persons-button'))
        .removeClass('active');

      e.preventDefault();
    });

    function getContactPersons() {
      $.ajax({
        url: '/api/persons',
      }).done(function(data) {
        rawContactPersons = data.results;
        $('body > div > header #menu-contact-persons').addClass('initialized');
        buildDOM();
      });
    }

    function getDepartments() {
      $.each(rawContactPersons, function() {
        if (this.hasOwnProperty('department')) {
          if (typeof departments[this.department] === 'undefined') {
            departments[this.department] = new Array();
          }
          if (this.hasOwnProperty('ressort')) {
            if (departments[this.department].indexOf(this.ressort) == -1) {
              departments[this.department].push(this.ressort);
              departments[this.department].sort();
            }
          }
        }
      });
    }

    function buildDOM() {
      getDepartments();

      keys = Object.keys(departments);
      keys.sort();

      var filterItems = '';
      var contactPersons = '';
      var closeButton = app.templates.ContactPersons.CloseButton({});
      var signet = $('body > div > header #menu-contact-persons .contact-persons-signet').get(0).outerHTML;

      for (i = 0; i < keys.length; i++) {
        var subFilter = '',
            subFilterItems = '';

        for (var key in departments[keys[i]]) {
          subFilterItems += app.templates.ContactPersons.FilterItem({department: i, ressort: key, title: departments[keys[i]][key]});
        }

        if (subFilterItems != '') {
          subFilterItems += signet;
          subFilter = app.templates.ContactPersons.FilterList({department: i, class: 'contact-persons-sub-filter', closeButton: closeButton, title: keys[i], filterItems: subFilterItems});
        }

        filterItems += app.templates.ContactPersons.FilterItem({department: i, title: keys[i], subFilter: subFilter});
      }

      var filter = app.templates.ContactPersons.FilterList({class: 'contact-persons-filter', filterItems: filterItems});

      $.each(rawContactPersons, function() {
        contactPersons += app.templates.ContactPersons.ContactPerson({
          departmentId: ((this.hasOwnProperty('department')) ? this.department : ''),
          ressortId: ((this.hasOwnProperty('ressort')) ? this.ressort : ''),
          salutation: ((this.hasOwnProperty('salutation')) ? this.salutation : ''),
          name: this.firstname + ' ' + this.lastname,
          department: ((this.hasOwnProperty('competence')) ? this.competence + '<br/>' : '') + ((this.hasOwnProperty('department')) ? this.department + '<br/>' : '') + ((this.hasOwnProperty('ressort')) ? this.ressort + '<br/>' : ''),
          address: this.street + '<br/>' + this.zip + ' ' + this.city,
          phone: ((this.hasOwnProperty('phone')) ? this.phone : ''),
          phoneLink: ((this.hasOwnProperty('linkablePhone')) ? this.linkablePhone : ''),
          fax: ((this.hasOwnProperty('fax')) ? this.fax : ''),
          mailLocal: ((this.hasOwnProperty('mailAlias')) ? this.mailAlias : ''),
          mailDomain: ((this.hasOwnProperty('mailDomain')) ? this.mailDomain : '')
        });
      });

      var searchInput = app.templates.ContactPersons.SearchInput();

      contactPersons = '<div class="contact-persons-wrapper">' + closeButton + searchInput + contactPersons + '</div>';

      $('body > div > header #menu-contact-persons .contact-persons-inner').html(filter + contactPersons);

      addEventListeners();
      addClearfixToContactPersons();
    }

    function addEventListeners() {
      $('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-filter li a').not('.contact-persons-back')
      .add($('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-filter .contact-persons-title'))
      .on('click', function(e) {
        if ($(this).hasClass('active')) {
          $(this).removeClass('active');
          filterContactPersonsByDepartment();
          return false;
        }

        $('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-filter li a').not('.contact-persons-back')
        .add($('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-filter .contact-persons-title'))
        .removeClass('active');

        if ($(e.target).is('.contact-persons-back')) {
          filterContactPersonsByDepartment();
          return false;
        }

        $(this).addClass('active');

        if ($(this).siblings('.contact-persons-sub-filter').length) {
          filterContactPersonsByDepartment($(this).data('department'));
          $(this).siblings('.contact-persons-sub-filter').add($(this).closest('#menu-contact-persons')).addClass('sub-filter-open');
        } else {
          if (!$(this).data('department')) {
            department = $(this).closest('.contact-persons-sub-filter').siblings('a').data('department');
          } else {
            department = $(this).data('department');
          }
          filterContactPersonsByDepartment(department, $(this).data('ressort'));
          $('body > div > header #menu-contact-persons').addClass('sub-filter-open');
          $(this).closest('.contact-persons-sub-filter').addClass('contact-persons-open');
          $('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-wrapper').addClass('open');
        }
        e.preventDefault();
      });

      $('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-back').on('click', function(e) {
        if (!$(this).parent().parent('.contact-persons-wrapper').length) {
          $(this).closest('.sub-filter-open')
            .add($(this).closest('.sub-filter-open').parent().closest('.sub-filter-open'))
            .removeClass('sub-filter-open');
        } else {
          if (!$('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-filter .sub-filter-open').length) {
            $('body > div > header #menu-contact-persons.sub-filter-open').removeClass('sub-filter-open');
          }
        }

        $('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-wrapper').removeClass('open');
        $('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-open').removeClass('contact-persons-open');
        e.preventDefault();
      });

      $('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-wrapper .contact-persons-search .contact-persons-search-input').on('keyup', function(e) {
        filterContactPersonsByString($(this).val());
      });
    }

    function filterContactPersonsByDepartment(departmentId, ressortId) {
      resetContactPersonsFilter();
      if (typeof departmentId !== 'undefined') {
        var department = keys[departmentId];

        if (typeof ressortId !== 'undefined') {
          var ressort = departments[department][ressortId];
        }

        $('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-wrapper').children('.contact-person').filter(function() {
          if (typeof department === 'undefined') {
            return true;
          } else {
            if (typeof ressort === 'undefined') {
              return $(this).data('department') != department;
            } else {
              return $(this).data('department') != department || $(this).data('ressort') != ressort;
            }
          }
        }).hide();
      }
      addClearfixToContactPersons();
    }

    function filterContactPersonsByString(q) {
      if (q == '') {
        $('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-wrapper .contact-person').removeClass('filter-active search-result');
      } else {
        $('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-wrapper .contact-person').addClass('filter-active').removeClass('search-result');
        $('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-wrapper .contact-person').each(function() {
          if ($(this).text().toLowerCase().indexOf(q.toLowerCase()) >= 0) {
            $(this).addClass('search-result');
          }
        });
      }
      addClearfixToContactPersons();
    }

    function resetContactPersonsFilter() {
      $('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-wrapper').children('.contact-person').show().removeClass('filter-active search-result');
      $('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-wrapper .contact-persons-search .contact-persons-search-input').val('');
    }

    function addClearfixToContactPersons() {
      $('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-wrapper .contact-person').removeClass('clearfix');
      if ($(window).width() < lgBreakpoint) {
        $('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-wrapper .contact-person:visible').filter(':even').addClass('clearfix');
      } else {
        $('body > div > header #menu-contact-persons .contact-persons-inner .contact-persons-wrapper .contact-person:visible').filter(function(index) {
          return index % 3 == 0;
        }).addClass('clearfix');
      }
    }
  }

  this.initLetterSizing = function() {
    if (sessionStorage.getItem('letter-size') != null) {
      $('body').addClass('letter-size-' + sessionStorage.getItem('letter-size'));
      $('body > div > header .menu-triggers .sub-triggers .lettering-controls .size-button .glyphicons-minus').addClass('visible');

      if (sessionStorage.getItem('letter-size') == 2) {
        $('body > div > header .menu-triggers .sub-triggers .lettering-controls .size-button .glyphicons-plus').addClass('invisible');
      }
    }

    $('body > div > header .menu-triggers .sub-triggers .lettering-controls .size-button .glyphicons-plus').on('click', function() {
      $('body > div > header .menu-triggers .sub-triggers .lettering-controls .size-button .glyphicons-minus').addClass('visible');
      if ($('body').hasClass('letter-size-1')) {
        $('body').removeClass('letter-size-1').addClass('letter-size-2');
        $('body > div > header .menu-triggers .sub-triggers .lettering-controls .size-button .glyphicons-plus').addClass('invisible');
        sessionStorage.setItem('letter-size', '2');
      } else {
        $('body').addClass('letter-size-1');
        sessionStorage.setItem('letter-size', '1');
      }
    });

    $('body > div > header .menu-triggers .sub-triggers .lettering-controls .size-button .glyphicons-minus').on('click', function() {
      if ($('body').hasClass('letter-size-2')) {
        $('body').removeClass('letter-size-2').addClass('letter-size-1');
        $('body > div > header .menu-triggers .sub-triggers .lettering-controls .size-button .glyphicons-plus').removeClass('invisible');
        sessionStorage.setItem('letter-size', '1');
      } else {
        $('body').removeClass('letter-size-1');
        $('body > div > header .menu-triggers .sub-triggers .lettering-controls .size-button .glyphicons-minus').removeClass('visible');
        sessionStorage.removeItem('letter-size');
      }
    });
  }

  this.initLetterContrast = function() {
    if (sessionStorage.getItem('letter-contrast') != null) {
      $('body').addClass('letter-contrast');
    }

    $('body > div > header .menu-triggers .sub-triggers .lettering-controls .contrast-button .glyphicons-adjust').on('click', function() {
      if ($('body').hasClass('letter-contrast')) {
        $('body').removeClass('letter-contrast');
        sessionStorage.removeItem('letter-contrast');
      } else {
        $('body').addClass('letter-contrast');
        sessionStorage.setItem('letter-contrast', true);
      }
    });
  }
}
